<template>
  <div :id="componentId">
    <KClipboardProvider v-slot="{ copyToClipboard }">
      <PortalKTooltip
        :label="showTooltip ? tooltipText : undefined"
        :placement="tooltipPlacement === 'bottom' ? 'bottom-end' : 'top-end'"
      >
        <component
          :is="computedTag"
          v-if="computedTag"
          :aria-label="tooltip || COPY"
          class="copy-button"
          data-testid="copy-button"
          type="button"
          @click.stop="copyText(copyToClipboard)"
        >
          <slot name="default">
            <CopyIcon
              decorative
              :size="iconSize"
            />
          </slot>
        </component>
      </PortalKTooltip>
    </KClipboardProvider>
  </div>
</template>

<script setup lang="ts">
import { CopyIcon } from '@kong/icons'
import { KUI_ICON_SIZE_50 } from '@kong/design-tokens'

const props = defineProps({
  /** Show or hide tooltip text */
  showTooltip: {
    type: Boolean,
    default: true,
  },
  /** The text to copy when clicked */
  text: {
    type: String,
    required: true,
  },
  /** The tooltip to display on hover */
  tooltip: {
    type: String,
    default: '',
  },
  /** The tooltip to display on copy success */
  tooltipSuccess: {
    type: String,
    default: '',
  },
  /** The placement of the tooltip */
  tooltipPlacement: {
    type: String as PropType<'top' | 'bottom'>,
    default: 'top',
    validator: (placement: string): boolean => ['top', 'bottom'].includes(placement),
  },
  /** Render a native `button` element (default) or a Kongponents `KButton` */
  buttonTag: {
    type: String,
    default: 'button',
    validator: (tag: string): boolean => ['button', 'PortalKButton'].includes(tag),
  },
  iconSize: {
    type: String,
    default: () => KUI_ICON_SIZE_50,
  },
  /** Provide custom CSS rules, scoped to this component */
  styles: {
    type: String,
    required: false,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'copied'): void
}>()

// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => props.styles), useAttrs().id as string)

const computedTag = computed((): 'button' | 'PortalKButton' => {
  // Render a PortalKButton if the `buttonTag` prop is set to 'PortalKButton' or 'KButton'
  if (props.buttonTag.includes('PortalKButton')) {
    return 'PortalKButton'
  }

  return 'button'
})

const COPY = 'Copy'
const COPIED = 'Copied!'

const tooltipText = useState<string>(`copy-tooltip-${componentId}`, () => props.tooltip || COPY)

const updateTooltipText = (msg?: string): void => {
  tooltipText.value = msg || props.tooltipSuccess || COPIED
  setTimeout(() => {
    tooltipText.value = props.tooltip || COPY
  }, 2000)
}

const copyText = (copyFn: (prop: string) => boolean) => {
  if (!copyFn(props.text)) {
    updateTooltipText('Failed to copy')

    return
  }

  updateTooltipText()

  emit('copied')
}
</script>

<style lang="scss" scoped>
button {
  background-color: var(--kui-color-background-transparent, $kui-color-background-transparent);
  border: none;
  border-radius: var(--kui-border-radius-20, $kui-border-radius-20);
  color: var(--kui-color-text-neutral, $kui-color-text-neutral);
  cursor: pointer;
  outline: none;
  padding: var(--kui-space-0, $kui-space-0);
  transition: color 0.2s ease-in-out;
}
</style>
